<template>
  <div class="slider-container">
    <swiper ref="mySwiper" class="groups-slider" :options="swiperOptions">
      <swiper-slide v-for="(slide,index) in GROUPS_OF_TOURNAMENTS" :key="slide.id" class="single-slide">
        <router-link :to="{ name: 'events-group', params: { id: GROUPS_OF_TOURNAMENTS[index].id }}">
          <img class="single-slide__bg" :src="GROUPS_OF_TOURNAMENTS[index].background" alt="#">
          <div class="single-slide__content">
            <div class="single-slide__inner-wrapper">
              <div class="top-wrapper">
                <div class="label">Группа турниров</div>
                <div class="partners">
                  <img :src="GROUPS_OF_TOURNAMENTS[index].sponsor.logo" alt="#">
                </div>
              </div>
              <div class="title">{{ GROUPS_OF_TOURNAMENTS[index].attributes.name }}</div>
              <div class="info">
                <div class="logo">
                  <img :src="getRelatedGame(GROUPS_OF_TOURNAMENTS[index].attributes.game_id).logo" alt="#">
                </div>
              </div>
              <div class="single-slide__button">
                <router-link :to="{ name: 'events-group', params: { id: GROUPS_OF_TOURNAMENTS[index].id }}">Посмотреть
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
      </swiper-slide>
    </swiper>
    <div v-show="GROUPS_OF_TOURNAMENTS.length >= 2" ref="controls" class="slider-controls">
      <div class="left">
        <div class="pseudo-svg">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 19L8 12L15 5" stroke="#E2FF31" stroke-width="1.5" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div class="right">
        <div class="pseudo-svg">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 19L8 12L15 5" stroke="#E2FF31" stroke-width="1.5" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "tournamentGroupsSlider",
  components: {Swiper, SwiperSlide},
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.right',
          prevEl: '.left'
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 0,
        breakpoints: {
          768: {
            centeredSlides: false,
          },
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'GROUPS_OF_TOURNAMENTS',
      'GAMES'
    ]),
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  watch: {},
  methods: {
    ...mapActions([
      'GET_GROUPS_OF_TOURNAMENTS',
    ]),

    getRelatedGame(gameId) {
      return this.GAMES.find(el => Number(el.id) === Number(gameId))
    },

    toggleControls() {
      this.swiper.slides.length <= 1
          ? this.$refs.controls.style.display = 'none'
          : this.$refs.controls.style.display = 'flex'
    }
  },
  mounted() {
    this.GET_GROUPS_OF_TOURNAMENTS()
  }
}
</script>

<style scoped lang="scss">

</style>