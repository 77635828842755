<template>
  <div class="tournaments__wrapper" v-if="!isPageDataLoad">
    <div ref="tournamentsBody" class="tournaments__body">
      <div class="tournaments__header">
        <media :query="{minWidth: 769}">
          <div class="section__title">Турниры</div>
        </media>
      </div>

      <tournament-groups-slider/>

      <div v-if="!listInProgress">
        <tournaments-list/>
      </div>
      <tournaments-list-skeleton v-else/>

    </div>
    <media :query="{minWidth: 1025}">
      <div class="tournaments__parameters">
        <div class="amount">
          Количество турниров:<span>{{ getAllCountOfTournaments }}</span>
        </div>
        <div class="filters-wrapper">
          <div class="filters-label">Выберите игру</div>
          <div class="filters-group">
            <select-dropdown :options="options" @input="filteringByGame" :initial="defaultFilters"
                             :option_id="option_id"/>
          </div>
          <div class="filters-button" @click="resetFilters">Сбросить фильтр</div>
        </div>
      </div>
    </media>
  </div>
  <tournaments-skeleton v-else/>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Media from 'vue-media';
import SelectDropdown from "@/components/UIElements/selectDropdown";

import TournamentsSkeleton from "@/views/sceleton/tournaments-skeleton";
import TournamentsListSkeleton from "@/views/sceleton/tournaments-list-skeleton";
import TournamentsList from "@/components/tournaments/tournamentsList";
import TournamentGroupsSlider from "@/components/tournaments/tournamentGroupsSlider";
import {ActiveEcho as PublicEcho} from "@/main";
import _ from 'lodash';

export default {
  name: "allTournaments",
  components: {
    TournamentGroupsSlider,
    TournamentsList, TournamentsListSkeleton, SelectDropdown, TournamentsSkeleton, Media
  },
  data() {
    return {
      filtersOpen: false,
      page_number: 1,
      page_size: 15,
      listInProgress: false,
      isPageDataLoad: true,
      options: [{
        id: 0,
        name: 'Все'
      }],
      defaultFilters: false,
      option_id: null,
      observer: null
    };
  },
  computed: {
    ...mapGetters([
      'TOURNAMENTS',
      'IS_TOURNAMENTS_IN_REQUEST',
      'GAMES',
      'GET_TOURNAMENTS_META'
    ]),

    getAllCountOfTournaments() {
      if (!this.GET_TOURNAMENTS_META) return '';
      return this.GET_TOURNAMENTS_META.page.total;
    }
  },
  methods: {
    ...mapActions([
      'GET_TOURNAMENTS',
      'GET_DISCIPLINES',
      'SET_TOURNAMENTS_REQUEST',
      'UPDATE_TOURNAMENT_IN_LIST'
    ]),
    ...mapMutations([
      'SET_TOURNAMENTS_LIST_FILTER'
    ]),
    filteringByGame(option) {
      this.$refs.tournamentsBody.scrollIntoView({block: 'start', behavior: 'smooth'});
      this.listInProgress = true;
      this.option_id = option.id;

      this.SET_TOURNAMENTS_LIST_FILTER({game_id: option.id});

      this.GET_TOURNAMENTS().then(() => {
        this.listInProgress = false;
        this.defaultFilters = false;
      });
    },
    /**
     * Устанавливаем значения для фильтра по игре
     * */
    setGamesFilter() {
      this.options = this.GAMES.map(item => {
        return {
          id: item.id,
          type: item.type,
          name: item.attributes.name
        };
      });
      this.options.unshift({
        id: 0,
        name: 'Все'
      });
    },
    resetFilters() {
      this.SET_TOURNAMENTS_LIST_FILTER({game_id: null});
      this.$refs.tournamentsBody.scrollIntoView({block: 'start', behavior: 'smooth'});
      this.option_id = null;
      this.listInProgress = true;
      this.defaultFilters = true;

      this.GET_TOURNAMENTS().then(() => {
        this.listInProgress = false;
      });
    },

    /**
     * Обновляем какой-то 1 турнир, не чаще 1 раза в 1 минуту
     * */
    updateTournamentInList: _.debounce(function (ev) {
      this.UPDATE_TOURNAMENT_IN_LIST(ev.tournament);
    }, 60000, {'leading': true, 'trailing': false}),

    /**
     * Подписываемся на обновления списка турниров
     * */
    subscribeToTournamentsUpdate() {
      PublicEcho.channel('tournaments')
          .listen('.TournamentChanged', ev => {
            this.updateTournamentInList(ev);
          });
    }
  },
  async created() {
    if (this.$route.params.game_id !== undefined) {
      this.SET_TOURNAMENTS_REQUEST(true);
      this.listInProgress = true;

      await this.GET_DISCIPLINES();
      this.setGamesFilter();

      this.SET_TOURNAMENTS_LIST_FILTER({game_id: this.$route.params.game_id});

      await this.GET_TOURNAMENTS();
      this.option_id = this.$route.params.game_id;
      this.listInProgress = false;
      this.defaultFilters = false;

      await this.SET_TOURNAMENTS_REQUEST(false);
    } else {
      await this.GET_TOURNAMENTS();
      this.setGamesFilter();
    }

    this.isPageDataLoad = false;
  },
  mounted() {
    this.subscribeToTournamentsUpdate();
  },
  beforeDestroy() {
    PublicEcho.leave('tournaments');
  }
};
</script>

<style lang="scss">
.sort-options {
  .custom-select {
    width: 186px;
  }
}

</style>
