<template>
  <div class="tournaments__list">
    <tournament-card :index="index" v-for="(tournament, index) in TOURNAMENTS" :key="tournament.id"/>
    <div ref="spinner" class="triggerForScroll" v-if="allDataIsLoaded">
      <spinner/>
    </div>
  </div>
</template>

<script>
import TournamentCard from "@/components/tournaments/tournamentCard";
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/UIElements/spinner";

export default {
  name: "tournamentsList",
  components: {Spinner, TournamentCard},
  data() {
    return {
      observer: null
    }
  },
  computed: {
    ...mapGetters([
      'TOURNAMENTS',
      'IS_TOURNAMENTS_IN_REQUEST',
      'GET_TOURNAMENTS_META',
      'GET_TOURNAMENTS_CONFIG'
    ]),

    allDataIsLoaded() {
      return !this.GET_TOURNAMENTS_CONFIG.allDataIsLoaded;
    }
  },
  methods: {
    ...mapActions([
      'SET_MORE_TOURNAMENTS'
    ])
  },
  mounted() {
    const target = this.$refs.spinner;
    if (target === undefined) {
      return null;
    }

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    }

    const callback = async (entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          await this.SET_MORE_TOURNAMENTS()
        }
      }
    }
    this.observer = new IntersectionObserver(callback, options);
    this.observer.observe(target);
  },

  destroyed() {
    if (this.observer) this.observer.disconnect()
  }
}
</script>

<style lang="scss" scoped>
.tournaments__list {
  .triggerForScroll {
    margin-top: 30px;
  }
}
</style>
